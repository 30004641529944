import {createStore} from 'vuex'

import AuthStore from "@/store/auth/auth.module";
import MainStore from "@/store/main/main.module";

import createPersistedState from "vuex-persistedstate";

export default createStore({
  namespaced: true,

  modules: {
    auth: AuthStore,
    main: MainStore,
  },

  state    : {},
  getters  : {},
  mutations: {},
  actions  : {
    foo() {
      // console.log('foo action')
    }
  },

  plugins: [createPersistedState()],

})
