import client from "@/services/axios";

export async function auth( credentials ) {
    var response = await client.post( `/api/auth/local`, {
        identifier: credentials.email,
        password: credentials.password
    } );
    return response.data;
}

export async function getPermissions(){
    var response = await client.get(`/api/reporting/permissions/me`);
    return response.data;
}
