import _ from 'lodash';

import authorsService from '@/services/authors-sales-report';
import marketingReportsService from '@/services/marketing-sales-report';

import {SALES_REPORT_ITEMS_LOADED, ALERT_ON_FAILURE, MARKETER_SALES_REPORT_ITEMS_LOADED} from './types';

const MainStore = {
    namespaced: true,

    state: () => ({
        salesReportItems        : [],
        marketerSalesReportItems: [],

        workItems: [],
        alerts   : []
    }),

    getters: {
        sortedSalesReportItems( state ) {
            return _.sortBy( state.salesReportItems, 'id' );
        },

        sortedMarketingSalesReportItems( state ) {
            return _.sortBy( state.marketerSalesReportItems, 'id' );
        },

        alerts( state ) {
            return state.alerts;
        },

    },

    mutations: {
        [SALES_REPORT_ITEMS_LOADED]( state, payload ) {
            state.salesReportItems = (payload?.data || []);
        },

        [MARKETER_SALES_REPORT_ITEMS_LOADED]( state, payload ) {
            state.marketerSalesReportItems = (payload?.data || []);
        },

        [ALERT_ON_FAILURE]( state, payload ) {
            if (payload) {
                state.alerts.push( payload );
            } else {
                state.alerts = [];
            }

        },

        ['RESET_STATE']( state ) {
            state.marketerSalesReportItems = [];
            state.salesReportItems = [];
            state.alerts = [];
        }
    },

    actions: {

        async ['RESET_STATE']( { commit } ) {
            commit( 'RESET_STATE' );
        },

        async getSalesReportItems( ctx, payload = {} ) {
            var { commit } = ctx;

            var response = await authorsService.find( payload.dateRange );
            commit( SALES_REPORT_ITEMS_LOADED, response );
        },

        async getMarketingSalesReportItems( ctx, payload = {} ) {
            var { commit } = ctx;

            var response = await marketingReportsService.find( payload.dateRange );
            commit( MARKETER_SALES_REPORT_ITEMS_LOADED, response );
        }
    },
};


export default MainStore;
