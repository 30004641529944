import {createRouter, createWebHistory} from 'vue-router'
import AuthorsSalesView from '../views/AuthorsSalesView.vue'
import MarketingSalesView from '../views/MarketingSalesView.vue'
import LoginView from "@/views/LoginView";
import NotFoundView from "@/views/NotFoundView";
import store from '../store';

const routes = [
    {
        path     : '/',
        name     : 'home',
        component: AuthorsSalesView,
        meta     : { reportKey: 'AUTHORS_SALES', requiresAuth: true },
    },
    {
        path     : '/sales-report',
        name     : 'sales-report',
        component: AuthorsSalesView,
        meta     : { reportKey: 'AUTHORS_SALES', requiresAuth: true },
    },
    {
        path     : '/marketing-sales-report',
        name     : 'marketing-sales-report',
        component: MarketingSalesView,
        meta     : { reportKey: 'MARKETERS_SALES', requiresAuth: true },
    },

    {
        path     : '/login',
        name     : 'login',
        component: LoginView,
        meta     : { guest: true },
    },
    {
        path     : '/404',
        name     : '404',
        component: NotFoundView,
        meta     : { guest: true },
    },


]

const router = createRouter( {
        history: createWebHistory( process.env.BASE_URL ),
        routes
    }
);

router.beforeEach( ( to, from, next ) => {
        if (to.matched.some( ( record ) => record.meta.requiresAuth )) {

            if (store.getters[`auth/isAuthenticated`]) {
                next();
                return;
            }

            next( "/login" );

        } else {

            next();
        }
    }
);

router.beforeEach( ( to, from, next ) => {

        if (to.matched.some( ( record ) => !!record.meta.reportKey )) {

            var availableReports = store.getters[`auth/availableReports`];
            if (availableReports.includes( to.meta.reportKey )) {
                next();
                return;
            }

            next( '/404' );

        } else {

            next();
        }
    }
);

router.beforeEach( ( to, from, next ) => {
        if (to.matched.some( ( record ) => record.meta.guest )) {
            if (store.getters[`auth/isAuthenticated`] && to.name === 'login') {
                next( "/" );
                return;
            }
            next();
        } else {
            next();
        }
    }
);

export default router;
