<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h4 class="text-center">404. Page Not Found.</h4>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'NotFoundView',
  mounted() {
  }
}

</script>
