<template>
  <div class="container">
    <div class="row">
      <h1>Отчет по продажам</h1>
    </div>

    <div class="row">
      <div class="col text-start">
        <div class="card">
          <div class="card-header">
            Период
          </div>
          <div class="card-body">
            <Datepicker v-model="reportDateRange"
                        weekStart="1"
                        range
                        auto-apply
                        showNowButton
                        :enableTimePicker="false"
                        :format="reportDateRangeFormat"
            />
          </div>
        </div>
      </div>
      <div class="col text-start">
        <div class="card" style="max-height: 200px; overflow: scroll">
          <h6 class="card-header">Студенты</h6>
          <div v-show="!distinctUsers.length" class="card-body">
            <small>Для выбора студентов выберите диапазон дат</small>
          </div>
          <div v-show="distinctUsers.length" class="card-body">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="all-users"
                     :checked="allUsersFilterChecked" @click="toggleAllUsersFilter"/>
              <label class="form-check-label" for="all-users">
                Все студенты
              </label>
            </div>

            <div class="form-check" v-for="item in distinctUsers" :key="item.id">
              <input class="form-check-input" type="checkbox" :value="item" :id="'users_' + item.id"
                     v-model="reportFilters.users">
              <label class="form-check-label" :for="'users_' + item.id">
                Студент #{{ item.id }}
              </label>
            </div>
          </div>


        </div>
      </div>
      <div class="col text-start">
        <div class="card " style="max-height: 200px; overflow: scroll">
          <h6 class="card-header">Авторы</h6>
          <div v-show="!distinctAuthors.length" class="card-body">
            <small>Для выбора авторов выберите диапазон дат</small>
          </div>

          <div v-show="distinctAuthors.length" class="card-body">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="all-authors"
                     :checked="allAuthorsFilterChecked" @click="toggleAllAuthorsFilter"/>
              <label class="form-check-label" for="all-authors">
                Все авторы
              </label>
            </div>
            <div class="form-check" v-for="item in distinctAuthors" :key="item.id">
              <input class="form-check-input" type="checkbox"
                     :value="item" :id="'authors_' + item.id" v-model="reportFilters.authors">
              <label class="form-check-label" :for="'authors_' + item.id">
                {{ item.name }}
              </label>
            </div>
          </div>


        </div>
      </div>
      <div class="col text-start">
        <div class="card" style="max-height: 200px; overflow: scroll">
          <h6 class="card-header">Курсы</h6>
          <div v-show="!distinctCourses.length" class="card-body">
            <small>Для выбора курсов выберите диапазон дат</small>
          </div>
          <div v-show="distinctCourses.length" class="card-body">

            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="all-courses"
                     :checked="allCoursesFilterChecked" @click="toggleAllCoursesFilter"/>
              <label class="form-check-label" for="all-courses">
                Все курсы
              </label>
            </div>

            <div class="form-check" v-for="item in distinctCourses" :key="item.id">
              <input class="form-check-input" type="checkbox" :value="item" :id="'courses_' + item.id"
                     v-model="reportFilters.courses">
              <label class="form-check-label" :for="'courses_' + item.id">
                {{ item.title }}
              </label>
            </div>
          </div>


        </div>

      </div>
    </div>
    <div class="row py-4">
      <div class="col">
        <AuthorSalesReportGrid :items="filteredReportItems" :totalSales="totalSales"/>
      </div>
    </div>
  </div>

</template>


<script>
// @ is an alias to /src
import '@vuepic/vue-datepicker/dist/main.css'
import Datepicker from '@vuepic/vue-datepicker';
import AuthorSalesReportGrid from "@/components/AuthorSalesReportGrid";
import {mapGetters} from 'vuex';
import _ from 'lodash';

export default {
  name      : 'AuthorsSalesView',
  components: {
    AuthorSalesReportGrid,
    Datepicker
  },

  data() {
    return {
      reportDateRange: null,

      reportFilters: {
        authors         : [],
        allAuthorsToggle: false,

        courses         : [],
        allCoursesToggle: false,

        users         : [],
        allUsersToggle: true,
      }

    }
  },


  computed: {

    allUsersFilterChecked() {
      return this.reportFilters.users.length === this.distinctUsers.length;
    },

    allAuthorsFilterChecked() {
      return this.reportFilters.authors.length === this.distinctAuthors.length;
    },

    allCoursesFilterChecked() {
      return this.reportFilters.courses.length === this.distinctCourses.length;
    },

    totalSales() {

      return _.reduce( this.filteredReportItems, ( sum, item ) => {
        return sum + (item.paymentTransaction?.total || 0)
      }, 0 )

    },

    filteredReportItems() {
      let items = _.chain( this.sortedReportItems )
          .filter( item => !!item.user )
          .value();

      let itemsByUsers = _.chain( items )
          .filter( item => this.reportFilters.users.map( u => u.id ).includes( item.user.id ) )
          .value();

      let itemsByAuthors = _.chain( itemsByUsers )
          .filter( item => !!item.course )
          .filter( item => !!item.course.author )
          .filter( item => this.reportFilters.authors.map( a => a.id ).includes( item.course.author.id ) )
          .value();

      let itemsByCourses = _.chain( itemsByAuthors )
          .filter( item => this.reportFilters.courses.map( co => co.id ).includes( item.course.id ) )
          .value();

      return itemsByCourses;
    },

    distinctUsers() {

      let items = _.chain( this.sortedReportItems )
          .filter( item => !!item.user )
          .uniqBy( item => item.user.id )
          .map( item => item.user )
          .value();

      return _.sortBy( items, item => item.id );
    },

    distinctAuthors() {

      let items2 = _.chain( this.sortedReportItems )
          .filter( item => !!item.course )
          .filter( item => !!item.course.author )
          .uniqBy( item => item.course.author.id )
          .map( item => item.course.author )
          .value();

      return _.sortBy( items2, item => item.name );
    },

    distinctCourses() {
      let items2 = _.chain( this.sortedReportItems )
          .filter( item => !!item.course )
          .uniqBy( item => item.course.id )
          .map( item => item.course )
          .value();

      return _.sortBy( items2, item => item?.title );
    },

    ...mapGetters( {
          sortedReportItems: 'main/sortedSalesReportItems',
        }
    )
  },

  watch: {

    'reportFilters.allUsersToggle': {
      immediate: true,

      handler: function ( newVal ) {
        if (newVal) {
          this.reportFilters.users = this.distinctUsers;
        } else {
          this.reportFilters.users = []
        }
      },

      deep: true
    },

    'reportFilters.allAuthorsToggle': {
      immediate: true,

      handler: function ( newVal ) {
        if (newVal) {
          this.reportFilters.authors = this.distinctAuthors;
        } else {
          this.reportFilters.authors = []
        }
      },

      deep: true
    },

    'reportFilters.allCoursesToggle': {
      immediate: true,

      handler: function ( newVal ) {
        if (newVal) {
          this.reportFilters.courses = this.distinctCourses;
        } else {
          this.reportFilters.courses = []
        }
      },

      deep: true
    },


    sortedReportItems: {
      immediate: true,
      handler  : function () {
        this.reportFilters.users = this.distinctUsers;
        this.reportFilters.authors = this.distinctAuthors;
        this.reportFilters.courses = this.distinctCourses;
      }
    },

    reportDateRange( newVal ) {
      var [from, to] = newVal;
      this.searchSalesReportItems( from, to );
    }
  },

  methods: {

    toggleAllCoursesFilter() {
      if (this.reportFilters.courses.length > 0) {
        this.reportFilters.courses = [];
      } else {
        this.reportFilters.courses = this.distinctCourses
      }
    },

    toggleAllAuthorsFilter() {
      if (this.reportFilters.authors.length > 0) {
        this.reportFilters.authors = [];
      } else {
        this.reportFilters.authors = this.distinctAuthors
      }
    },

    toggleAllUsersFilter() {
      if (this.reportFilters.users.length > 0) {
        this.reportFilters.users = [];
      } else {
        this.reportFilters.users = this.distinctUsers
      }
    },

    reportDateRangeFormat( date ) {
      var [from, to] = date;
      const formatDate = ( date ) => new Intl.DateTimeFormat( 'ru-RU' ).format( date );
      return [`${ formatDate( from ) } - ${ formatDate( to ) }`];
    },

    searchSalesReportItems( dateFrom, dateTo ) {
      this.$store.dispatch( 'main/getSalesReportItems', { dateRange: { dateFrom, dateTo } } )
    },
  },

  mounted() {
  }
}
</script>
