<template>
  <td>{{ formatDate( item.createdAt ) }}</td>
  <td>Студент&nbsp;#{{ item.user?.id }}</td>
  <td>{{ item.course?.author?.name }}</td>
  <td>{{ item.course?.title }}</td>
  <td>
    {{ item.course.free_course ? 'бесплатно' : formatPrice( item.course.price_override || item.course.price ) }}

    <p class="text-danger" v-for="e in item.errors" :key="e">
      {{ e.key === 'MISSING_IN_PAYMENT_TRANSACTION' ? 'Нет информации от Робокассы' : '' }}
    </p>
  </td>
  <td>Просмотрено {{ item.course?.progress.lessonsCompleted }}/{{ item.course?.progress.lessonsCount }}</td>
<!--  <td>-->
<!--    <p v-for="e in item.errors" :key="e">{{ e.key }}</p>-->
<!--  </td>-->
</template>

<script>


export default {
  name: 'AuthorSalesReportGridRowReadOnly',

  props: ['item'],

  methods: {
    formatDate( date ) {
      // TODO: move to filters
      if (!date) {
        return 'нет информации о дате'
      }

      return new Intl.DateTimeFormat( 'ru-RU', { dateStyle: 'long' } ).format( new Date( date ) )
    },
    formatPrice( number ) {
      // TODO: move to filters
      if (!number) {
        return 'нет информации о стоимости'
      }

      return new Intl.NumberFormat( 'ru-RU', { style: 'currency', currency: 'RUB' } ).format( number );
    },
  },

  mounted() {

  }
}
</script>
